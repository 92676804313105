@import '../../../app.scss';

.APMReceipt {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.6vw;
  height: auto;
 

  background-color:  var(--general-background-light) !important;
  color: $zarph-white;
  [data-theme='dark'] & {
    background-color:  var(--general-background-dark) !important;
    color: $zarph-white;
  };

  .apmReceiptDetails{
    font-weight: 500;
    color:  var(--text-color-light);
    [data-theme='dark'] & {
      color:  var(--text-color-dark);
    }
  }
}