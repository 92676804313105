@import '../../../app.scss';

.UsersFilterBar {
  margin: 2vw 0 2vw 0;
  width: 84vw;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }
  @media only screen and (max-width: 575px) {
    display: flex !important;
    justify-content: space-around !important;
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    display: flex !important;
    justify-content: center !important;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
  }

  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
  }

  @media only screen and (min-width: 1600px) {
    width: 92vw;
  }

  @media only screen and (min-width: 2560px) {
  }

  .mantine-Select-root,
  .mantine-InputWrapper-root {
    width: auto;
    display: flex;
    align-items: center;
    margin-top: 0 !important;
    height: 4vh;
    @media only screen and (max-width: 575px) {
      margin-bottom: 2vh;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      margin-bottom: 1.5vh !important;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 0.6vh !important;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      margin-bottom: 0.6vh !important;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 27vw;
    }

    @media only screen and (min-width: 1600px) {
      width: 17vw;
    }

    @media only screen and (min-width: 2560px) {
    }

    .mantine-InputWrapper-label {
      font-weight: 700;
      color: var(--title-color-light);
      [data-theme='dark'] & {
        color: var(--title-color-dark);
      }
      @media only screen and (max-width: 575px) {
        width: 26vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 20vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 13vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 13vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 10vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 16vw;
      }

      @media only screen and (min-width: 2560px) {
      }
    }
    .mantine-Input-wrapper {
      @media only screen and (max-width: 575px) {
        width: 54vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 45vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 25vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 25vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 15vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 32vw;
      }

      @media only screen and (min-width: 2560px) {
      }
    }
  }
  .mantine-TextInput-root {
    width: auto;
    display: flex;
    align-items: center;
    margin-top: 0 !important;
    height: 4vh;
    @media only screen and (max-width: 575px) {
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 27vw;
    }

    @media only screen and (min-width: 1600px) {
      width: 17vw;
    }

    @media only screen and (min-width: 2560px) {
    }

    .mantine-InputWrapper-label {
      font-weight: 700;
      color: var(--title-color-light);
      [data-theme='dark'] & {
        color: var(--title-color-dark);
      }
      @media only screen and (max-width: 575px) {
        width: 26vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 20vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 13vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 13vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 10vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 16vw;
      }

      @media only screen and (min-width: 2560px) {
      }
    }
    .mantine-Input-wrapper {
      @media only screen and (max-width: 575px) {
        width: 54vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 45vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 25vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 25vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 15vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 32vw;
      }

      @media only screen and (min-width: 2560px) {
      }
    }
  }

  .mantine-Button-root {
    background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    }
    @media only screen and (max-width: 575px) {
      width: 40vw;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 30vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 19vw;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 12vw;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 8vw;
    }

    @media only screen and (min-width: 1600px) {
      width: 12vw;
    }

    @media only screen and (min-width: 2560px) {
    }
    .XLSButton svg,
    .PDFButton svg {
      color: $zarph-white;
    }
  }
}
