$zarph-color: #004960;
$zarph-secondary: #016273;
$zarph-light-color: #47d4ff;
$zarph-green: #03abac;
$zarph-dark-color: #003f52;
$zarph-white: #fcfcfc;

:root {
  --general-background-light: var(--mantine-zarph-9);
  --background-color-light: var(--mantine-color-zarph-1);
  --background-color-hover-light: var(--mantine-color-zarph-3);
  --title-color-light: var(--mantine-color-zarph-0);
  --text-color-light: var(--mantine-color-zarph-8);
}

[data-theme='dark'] {
  --general-background-dark: var(--mantine-zarph-8);
  --background-color-dark: var(--mantine-color-zarph-1);
  --background-color-hover-dark: var(--mantine-color-zarph-4);
  --title-color-dark: var(--mantine-color-zarph-9);
  --text-color-dark: var(--mantine-color-zarph-9);
}

#root {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

body {
  font-size: 1em;
  height: auto;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.activeRow {
  background-color: var(--white-color);
}

.content {
  margin: 3vh 0 0 3vw;
  height: auto;
  min-height: 100%;
  overflow: visible;

  h2 {
    margin-top: 0;
    margin-bottom: 2vh;
  }
}

.mantine-TableScrollContainer-scrollContainer {
  text-align: center;
  overflow: auto;
  height: auto;
  .mantine-Table-thead {
    width: 100%;

    .mantine-Table-th {
      text-align: center;
      width: 5%;
      color: var(--primary-color);
    }
  }

  .mantine-Table-td svg {
    color: var(--dark-color);
  }
}

.mantine-contextmenu {
  padding: 1vw;

  .mantine-contextmenu-item-button {
    margin-bottom: 0.5vh;

    .mantine-contextmenu-item-button-color {
      &:hover {
        color: var(--general-background-light);
        [data-theme='dark'] & {
          color: var(--general-background-dark);
        }
      }
    }
  }
}

/* Media queries... (same as before) */
