@import '../../../app.scss';

.login {
  background: linear-gradient(169deg, rgba(0, 109, 143, 1) 0%, rgba(0, 73, 96, 1) 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  .mantine-Grid-inner, .mantine-Grid-root {
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  .mantine-Grid-col, .mantine-Group-root {
    display: flex;
    justify-content: center;
    flex-direction: column;

    img {
      width: 10vw;
      @media only screen and (max-width: 575px) {
        width: 50vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 45vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 40vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 35vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 30vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 25vw;
      }

      @media only screen and (min-width: 2560px) {
        width: 15vw;
      }
    }
  }

  .mantine-TextInput-label, .mantine-PasswordInput-label {
    color: white;
    font-size: medium;
    margin-top: 2vh;
  }

  .mantine-TextInput-wrapper, .mantine-PasswordInput-wrapper{
    min-width: 150px;
      @media only screen and (max-width: 575px) {
        width: 35vw;
      }
  
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 30vw;
      }
  
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 25vw;
      }
  
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 16vw;
      }
  
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 13vw;
      }
  
      @media only screen and (min-width: 1600px) {
        width: 10vw;
      }
  
      @media only screen and (min-width: 2560px) {
        width: 6vw;
      }
  }

  .mantine-Select-root {
    .mantine-Select-wrapper{
      margin-top: 2vh;

      @media only screen and (max-width: 575px) {
        width: 30vw;
      }
  
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 25vw;
      }
  
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 14vw;
      }
  
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 12vw;
      }
  
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 13vw;
      }
  
      @media only screen and (min-width: 1600px) {
        width: 9vw;
      }
  
      @media only screen and (min-width: 2560px) {
        width: 6vw;
      }
    }
  }
  .mantine-Button-root {
    --button-bd: 1px solid white;
    --button-bg: #006d8f;
    --button-hover: #004960;
    margin-top: 2vh;
  }
}

