@import '../../../app.scss';

.edit-equipment {
  background-color: var(--general-background-ligh) !important;
  color: var(--title-color-light) !important;
  [data-theme='dark'] & {
    color: var(--title-color-dark) !important;
  }

  background-color: var(--general-background-light) !important;
  color: $zarph-white;
  [data-theme='dark'] & {
    background-color: var(--general-background-dark) !important;
  }

  .hours-minutes {
    @media only screen and (max-width: 575px) {
      width: 72vw;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 62vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 52vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    }
    @media only screen and (min-width: 1600px) {
    }
    @media only screen and (min-width: 2560px) {
    }

    .mantine-InputWrapper-root {
      width: 30vw;
      margin-bottom: 2vh;
      @media only screen and (max-width: 575px) {
        width: 20vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 25vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 15vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 12vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 10vw;
      }
      @media only screen and (min-width: 1600px) {
        width: 8vw;
      }
      @media only screen and (min-width: 2560px) {
        width: 6vw;
      }

      .mantine-TextInput-wrapper {
        width: 8vw;
        @media only screen and (max-width: 575px) {
          width: 20vw;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 25vw;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 15vw;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 12vw;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 10vw;
        }
        @media only screen and (min-width: 1600px) {
          width: 8vw;
        }
        @media only screen and (min-width: 2560px) {
          width: 6vw;
        }
      }

      .mantine-TextInput-label {
        font-size: medium;
        margin-bottom: 1vh;
      }
    }
  }
  .description {
    .mantine-Textarea-root {
      width: 45vw;
      margin-bottom: 2vh;

      .mantine-Textarea-label {
        font-size: medium;
        margin-bottom: 1vh;
      }
      .mantine-Textarea-input {
        height: 8vh;
        width: 88%;

        text-align: start;
        @media only screen and (max-width: 575px) {
          height: 10vh;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          height: 10vh;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          height: 10vh;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          height: 10vh;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          height: 10vh;
        }
        @media only screen and (min-width: 1600px) {
          height: 10vh;
        }
        @media only screen and (min-width: 2560px) {
          height: 10vh;
        }
      }
    }
  }
}
