@import '../../../../app.scss';

.helpdesk-ticket-edit {
  width: 95%;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 2vh;

  @media only screen and (max-width: 575px) {
    top: 10%;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 87%;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 90%;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    width: 90%;
  }

  .mantine-Grid-root {
    margin: 5% 0 2.5% 0;
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;

      @media only screen and (max-width: 575px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .mantine-Group-root svg {
      margin-top: 12%;

      @media only screen and (max-width: 575px) {
        margin-top: 6%;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-top: 6%;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      }
      @media only screen and (min-width: 1600px) {
      }
      @media only screen and (min-width: 2560px) {
      }
    }
  }
  .mantine-Button-root {
    margin: 2% 0 2% 0;
    background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    }
  }
}

.helpdesk-ticket-read {
  width: 95%;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 2vh;

  @media only screen and (max-width: 575px) {
    top: 10%;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 87%;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 90%;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    width: 90%;
  }

  .mantine-Grid-root {
    margin: 5% 0 2.5% 0;
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;

      @media only screen and (max-width: 575px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .mantine-Group-root svg {
      margin-top: 12%;

      @media only screen and (max-width: 575px) {
        margin-top: 6%;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-top: 6%;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      }
      @media only screen and (min-width: 1600px) {
      }
      @media only screen and (min-width: 2560px) {
      }
    }
  }

  .mantine-Table-table {
    margin-top: 6%;
  }
  .mantine-Button-root {
    margin: 2% 0 2% 0;
    background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    }
  }
}

@media only screen and (max-width: 575px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1600px) {
}
@media only screen and (min-width: 2560px) {
}
