@import '../../../app.scss';

.currency-table,
.bills-table {
  text-align: center;
  margin-top: 1vh;
  .mantine-Table-table {
    background-color: var(--background-color-light) !important;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark) !important;
      color: $zarph-white;
    }
    text-align: center;
  }

  .mantine-Table-thead {
    background-color: var(--background-color-light) !important;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark) !important;
      color: $zarph-white;
    }
    color: white;
    border: 1px solid white;

    .mantine-Table-th {
      text-align: center;
      width: 5%;
      border: 1px solid white;
    }
  }

  .mantine-Table-td {
    .check {
      width: 0.9vw;
      fill: #538539;
    }
    .cross {
      width: 0.9vw;
      fill: red;
    }
  }
}

.header {
  height: 6vh;
  h1 {
    color: var(--title-color-light);
    [data-theme='dark'] & {
      color: var(--title-color-dark);
    }
  }
}

.background {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end !important;
  img {
    width: 5vw;
    opacity: 0.6;
    [data-theme='dark'] & {
      opacity: 0.8;
    }
    position: absolute;
    top: 2%;
    right: 2%;
    @media only screen and (max-width: 575px) {
      width: 15vw;
      margin: 0 6vw 0 0;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 15vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 10vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 10vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 8vw;
    }
    @media only screen and (min-width: 1600px) {
    }
    @media only screen and (min-width: 2560px) {
      width: 3vw;
    }
  }
}
