@import '../../../app.scss';

.product-table {
  text-align: center;
  width: 100%;
  .mantine-Table-table {
    background-color: var(--general-background-light) !important;
    [data-theme='dark'] & {
      background-color: var(--general-background-dark) !important;
      color: $zarph-white;
    }
    text-align: center;
  }

  .mantine-Table-thead {
    background-color: var(--background-color-light) !important;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark) !important;
      color: $zarph-white;
    }
    color: var(--text-color-light);
    [data-theme='dark'] & {
      color: var(--text-color-dark);
    }
    border: 1px solid white;

    .mantine-Table-th {
      text-align: center;
      width: 5%;
      border: 1px solid white;
      color: var(--title-color-light);
      [data-theme='dark'] & {
        color: var(--title-color-dark);
      }
    }
  }
}
