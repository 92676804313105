@import '../../../app.scss';

.show-equipment-details {
  margin-top: 20px;
  height: auto;
  display: flex;
  flex-direction: column;

  .mantine-Group-root {
    display: block;
  }

  .mantine-Grid-root {
    .mantine-Grid-inner {
      .mantine-Grid-col {
        padding: 0.5vh 0.5vw 0.5vh 0;
        width: 100%;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      }
      @media only screen and (min-width: 1600px) {
      }
      @media only screen and (min-width: 2560px) {
      }
    }
  }

  .onlineStatus,
  .inService {
    color: #538539 !important;
  }

  .offlineStatus,
  .notInService {
    color: #861724 !important;
  }

  .status-table {
    width: 75%;
    margin: 25px auto;
  }

  .equip-details-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    @media only screen and (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}

@media only screen and (max-width: 575px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1600px) {
}
@media only screen and (min-width: 2560px) {
}
