@import '../../../app.scss';

.mantine-AppShell-root {
  .mantine-AppShell-header {
    display: flex;
    align-items: center;
    border: none;
    height: 4vh;
    z-index: 104;
    background-color: var(--background-color-light);
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
    }
    @media only screen and (max-width: 575px) {
      height: 10vh;
    }

    .mantine-Burger-root {
      margin-left: 1vw;
      @media only screen and (max-width: 575px) {
        margin: 0 4vw 0 4vw;
        --burger-size: 5vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        margin: 0 3.5vw 0 2.2vw;
        --burger-size: 3vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin: 0 3vw 0 1.5vw;
        --burger-size: 2vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        margin: 0 2vw 0 1.6vw;
        --burger-size: 1.8vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        margin: 0 1vw 0 1.2vw;
        --burger-size: 1.8vw;
      }
      @media only screen and (min-width: 1600px) {
        margin: 0 1vw 0 0.8vw;
        --burger-size: 1vw;
      }
      @media only screen and (min-width: 2560px) {
        margin: 0 1vw 0 0.6vw;
        --burger-size: 0.8vw;
      }
    }
  }

  .mantine-AppShell-navbar {
    background-color: var(--background-color-light);
    position: fixed;
    top: 4vh;
    height: 100vh;
    border: none;
    padding: 2vh 3.5vw 2vh 4vw;

    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
    }
    @media only screen and (max-width: 575px) {
      width: 55vw;
      top: 10vh;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 32vw;
      top: 0vh;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 25vw;
      top: 0vh;
      padding: 2vh 1.5vw 2vh 2.5vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 22vw;
      top: 0vh;
      padding: 2vh 1.5vw 2vh 2.5vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 16vw;
      top: 0vh;
      padding: 2vh 1.5vw 2vh 1.5vw;
    }
    @media only screen and (min-width: 1600px) {
      top: 0vh;
      padding: 2vh 1vw 2vh 1vw;
    }
    @media only screen and (min-width: 2560px) {
      top: 0vh;
      padding: 2vh 1vw 2vh 1vw;
    }

    .logo {
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 20vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 18vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 14vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 11vw;
      }
      @media only screen and (min-width: 1600px) {
        width: 7.5vw;
      }
      @media only screen and (min-width: 2560px) {
        width: 8vw;
      }
      @media only screen and (min-width: 3760px) {
        width: 5vw;
      }
    }

    .options {
      .mantine-Burger-root {
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          --burger-size: 2.5vw;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          --burger-size: 2.2vw;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          --burger-size: 1.5vw;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          --burger-size: 1.2vw;
        }
        @media only screen and (min-width: 1600px) {
          --burger-size: 1vw;
        }
        @media only screen and (min-width: 2560px) {
          --burger-size: 0.8vw;
        }
      }
      .menu {
        display: flex;
        align-content: flex-start;
        overflow-x: auto;

        @media only screen and (max-width: 575px) {
          width: 49vw;
          height: 56vh;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 28vw;
          height: 65vh;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 22vw;
          height: 58vh;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 22vw;
          height: 58vh;
          margin: 0 0 6vh 0;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 14vw;
          height: 62vh;
          margin: 0 0 6vh 0;
        }

        @media only screen and (min-width: 1600px) {
          width: 10vw;
          height: 62vh;
        }

        @media only screen and (min-width: 2560px) {
          width: 10vw;
          height: 60vh;
        }

        .mantine-Image-root,
        img {
          color: $zarph-white !important;
          width: 4.5vw;
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2.5vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2.2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 1.5vw;
          }
          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1.2vw;
          }
          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }
          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }
        span {
          font-size: 1vw;
          color: $zarph-white;
          font-weight: 400;
          @media only screen and (max-width: 575px) {
            font-size: 3vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            font-size: 1.8vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            font-size: 1.2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            font-size: 1vw;
          }
          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            font-size: 0.8vw;
          }
          @media only screen and (min-width: 1600px) {
            font-size: 0.6vw;
          }
          @media only screen and (min-width: 2560px) {
            font-size: 0.5vw;
          }
        }
      }
    }

    .profile-options {
      display: flex;
      align-content: flex-start;
      flex-direction: column;
      position: fixed;
      bottom: 0.5vh;
      margin-left: 0.2vw;

      @media only screen and (max-width: 575px) {
        width: 49vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 28vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 22vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 22vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 14vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 10vw;
      }

      @media only screen and (min-width: 2560px) {
        width: 10vw;
      }

      .mantine-Image-root,
      img {
        color: $zarph-white !important;
        width: 4.5vw;
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 2.5vw;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 2.2vw;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 1.5vw;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 1.2vw;
        }
        @media only screen and (min-width: 1600px) {
          width: 1vw;
        }
        @media only screen and (min-width: 2560px) {
          width: 0.8vw;
        }
      }
      span {
        font-size: 1vw;
        color: $zarph-white;
        font-weight: 400;
        @media only screen and (max-width: 575px) {
          font-size: 3vw;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          font-size: 1.8vw;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          font-size: 1.2vw;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          font-size: 1vw;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          font-size: 0.8vw;
        }
        @media only screen and (min-width: 1600px) {
          font-size: 0.6vw;
        }
        @media only screen and (min-width: 2560px) {
          font-size: 0.5vw;
        }
      }
    }
    .mantine-focus-auto {
      width: auto !important;
      padding: 0.3vh 0.5vw 0.3vh 0.5vw;
      border-radius: 10px;
      &:hover {
        background-color: var(--background-color-hover-light);
        [data-theme='dark'] & {
          background-color: var(--background-color-hover-dark);
        }
      }
      @media only screen and (max-width: 575px) {
        padding: 1vh;
      }
    }
  }
}

.collapsed-navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  background-color: var(--background-color-light);
  [data-theme='dark'] & {
    background-color: var(--background-color-dark);
  }
  width: 3vw;
  height: 100vh;
  overflow: hidden;
  padding: 2vw 1.5vh 2vw 1.5vh;
  @media only screen and (max-width: 575px) {
    width: 0 !important;
    padding: 0 !important;
    visibility: hidden;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 9vw;
    top: 0vh;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 6vw;
    top: 0vh;
    padding: 2.8vw 1.5vh 1vw 1.5vh;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    width: 6vw;
    top: 0vh;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 4vw;
    top: 0vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 3vw;
    top: 0vh;
    padding: 1vw 1.5vh 1vw 1.5vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 3vw;
    top: 0vh;
    padding: 1vw 1.5vh 1vw 1.5vh;
  }

  .initial {
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 4vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 3vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 2.5vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 1.8vw;
    }
    @media only screen and (min-width: 1600px) {
      width: 1.5vw;
    }
    @media only screen and (min-width: 2560px) {
      width: 1.5vw;
    }
  }

  .options {
    flex-direction: column;
    justify-content: center;
    .navbar-header {
      flex-direction: column;

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 6vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 4vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 4vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 2.5vw;
      }
      @media only screen and (min-width: 1600px) {
        width: 2.5vw;
      }
      @media only screen and (min-width: 2560px) {
        width: 2.5vw;
      }

      .mantine-Burger-root {
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          --burger-size: 2.5vw;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          --burger-size: 2.2vw;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          --burger-size: 1.5vw;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          --burger-size: 1.2vw;
        }
        @media only screen and (min-width: 1600px) {
          --burger-size: 1vw;
        }
        @media only screen and (min-width: 2560px) {
          --burger-size: 0.8vw;
        }
      }
    }
    .menu {
      flex-direction: column;
      align-items: center;
      overflow-x: auto;
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 6vw;
        height: 60vh;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 4vw;
        height: 58vh;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 4vw;
        height: 58vh;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 2.5vw;
        height: 62vh;
      }

      @media only screen and (min-width: 1600px) {
        width: 2.5vw;
        height: 62vh;
      }

      @media only screen and (min-width: 2560px) {
        width: 2.5vw;
        height: 58vh;
      }

      .mantine-Image-root,
      img {
        color: $zarph-white !important;
        width: 4vw;
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 2.5vw;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 2vw;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 1.5vw;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 1.2vw;
        }
        @media only screen and (min-width: 1600px) {
          width: 1vw;
        }
        @media only screen and (min-width: 2560px) {
          width: 0.8vw;
        }
      }
    }
  }

  .profile-options {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .mantine-focus-auto {
      border-radius: 10px;
      &:hover {
        background-color: var(--background-color-hover-light);

        [data-theme='dark'] & {
          background-color: var(--background-color-hover-dark);
        }
      }
      .mantine-Group-root {
        .mantine-Image-root,
        img {
          color: $zarph-white !important;
          width: 4vw;
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2.5vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 1.5vw;
          }
          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1.2vw;
          }
          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }
          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }
        span {
          display: none;
        }
      }
      &:last-of-type {
        display: inline;
      }
    }
  }
  .mantine-focus-auto {
    width: auto !important;
    padding: 0.3vh 0.5vw 0.3vh 0.5vw;
    border-radius: 10px;
    &:hover {
      background-color: var(--background-color-hover-light);
      [data-theme='dark'] & {
        background-color: var(--background-color-hover-dark);
      }
    }
    @media only screen and (max-width: 575px) {
      padding: 1vh;
    }
  }
}

.react-version {
  font-size: 0.5vw;
  color: $zarph-white;

  @media only screen and (max-width: 575px) {
    font-size: 2vw !important;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    font-size: 1vw !important;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 0.7vw !important;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 0.6vw !important;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    font-size: 0.5vw !important;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 0.4vw !important;
  }
  @media only screen and (min-width: 2560px) {
    font-size: 0.3vw !important;
  }
}
