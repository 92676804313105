@import '../../../app.scss';

.pagination {
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 1vh;

  .mantine-Pagination-root{
    --pagination-active-bg: var(--background-color-light);
    [data-theme='dark'] & {
      --pagination-active-bg: var(--background-color-dark);
    }
}

  .showValues {
    display: flex;
    align-items: center;
  }

  .mantine-InputWrapper-root.mantine-InputBase-root {
    margin-top: 0 !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 575px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

@media only screen and (min-width: 2560px) {
}
