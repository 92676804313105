@import '../../../../app.scss';

.ticket-edit {
  width: 95%;

  .mantine-Grid-root {
    margin: 5% 0 2.5% 0;
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;

      @media only screen and (max-width: 575px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.description-comment {
  margin-bottom: 4%;
  .mantine-Textarea-root {
    width: 98%;
    .mantine-Textarea-input {
      height: 10vh;
    }
  }
}
@media only screen and (max-width: 575px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1600px) {
}
@media only screen and (min-width: 2560px) {
}
