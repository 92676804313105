@import '../../../../app.scss';

.helpdesk-entity-new {
  width: 50vw;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 5vw;
  top: 2vh;
  height: 90%;

  @media only screen and (max-width: 575px) {
    left: 6vw;
    top: 14vh;
    width: 90vw;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    left: 16vw;
    width: 78vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    left: 10vw;
    width: 89vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    left: 14vw;
    width: 81vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    left: 24vw;
    width: 62vw;
  }
  @media only screen and (min-width: 1600px) {
    left: 22vw;
    width: 60vw;
  }
  @media only screen and (min-width: 2560px) {
    left: 28vw;
    width: 50vw;
  }

  .mantine-Grid-root {
    margin-bottom: 6vh;
  }

  .mantine-Group-root {
    @media only screen and (max-width: 575px) {
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    }
    @media only screen and (min-width: 1600px) {
    }
    @media only screen and (min-width: 2560px) {
      width: 24vw;
    }
  }

  .mantine-InputWrapper-root {
    @media only screen and (max-width: 575px) {
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin-right: 2vw;
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      margin-right: 2vw;
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 1600px) {
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 2560px) {
      display: flex;
      align-items: center;
    }

    .mantine-TextInput-label,
    .mantine-Select-label {
      margin-bottom: 0.8vh;
      @media only screen and (max-width: 575px) {
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 16vw;
        margin-right: 5vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 14vw;
        margin-right: 3vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 14vw;
        margin-right: 4vw;
      }
      @media only screen and (min-width: 1600px) {
        width: 10vw;
        margin-right: 2vw;
      }
      @media only screen and (min-width: 2560px) {
        width: 8vw;
        margin-right: 2vw;
      }
    }

    .mantine-TextInput-wrapper,
    .mantine-Select-wrapper {
      width: 12vw;
      @media only screen and (max-width: 575px) {
        width: 34vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 32vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: 1.5vw;
        width: 28vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 22vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        margin-left: 1vw;
        width: 30vw;
      }
      @media only screen and (min-width: 1600px) {
        width: 20vw;
      }
      @media only screen and (min-width: 2560px) {
        margin-left: 0.4vw;
        width: 26vw;
      }
    }
  }

  .wide-input {
    .mantine-TextInput-wrapper,
    .mantine-Select-wrapper {
      width: 35vw;
      @media only screen and (max-width: 575px) {
        width: 74vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 66vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: -4vw;
        width: 60vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-left: -3vw;
        width: 55vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        margin-left: -6vw;
        width: 43vw;
      }
      @media only screen and (min-width: 1600px) {
        margin-left: -2vw;
        width: 43vw;
      }
      @media only screen and (min-width: 2560px) {
        margin-left: -3vw;
        width: 37.5vw;
      }
    }
  }

  .accordion {
    position: relative;
    top: 2vh;
    margin-bottom: 4vh;
    @media only screen and (max-width: 575px) {
      left: -1vw;
      width: 84vw;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      left: -2vw;
      width: 74vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      left: -2vw;
      width: 86vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      left: -3vw;
      width: 78vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      left: -2vw;
      width: 61vw;
    }
    @media only screen and (min-width: 1600px) {
      left: -2vw;
      width: 58vw;
    }
    @media only screen and (min-width: 2560px) {
      left: -1.5vw;
      width: 48vw;
    }

    .mantine-Accordion-label {
      font-weight: 700;
    }

    .mantine-Accordion-item {
      padding: 0.5vw;
      /*border: #4b4b4b solid 2px;*/
    }

    .mantine-Accordion-panel {
      @media only screen and (max-width: 575px) {
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: 0.5vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-left: 1vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        margin-left: 0.5vw;
      }
      @media only screen and (min-width: 1600px) {
        margin-left: 1vw;
      }
      @media only screen and (min-width: 2560px) {
        margin-left: 0.5vw;
      }
    }
  }

  .submit-save-buttons {
    .mantine-Button-root {
      width: 6vw;
      @media only screen and (max-width: 575px) {
        width: 18vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 16vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 14vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 12vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 10vw;
      }
      @media only screen and (min-width: 1600px) {
      }
      @media only screen and (min-width: 2560px) {
      }
    }
  }
}

.helpdesk-entity-read {
  width: 50vw;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 5vw;
  top: 10vh;
  height: 90%;

  @media only screen and (max-width: 575px) {
    left: 6vw;
    top: 14vh;
    width: 90vw;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    left: 16vw;
    width: 78vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    left: 12vw;
    width: 89vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    left: 14vw;
    width: 81vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    left: 24vw;
    width: 62vw;
  }
  @media only screen and (min-width: 1600px) {
    left: 22vw;
    width: 60vw;
  }
  @media only screen and (min-width: 2560px) {
    left: 28vw;
    width: 50vw;
  }

  .mantine-Grid-root {
    margin-bottom: 6vh;
  }

  .mantine-Group-root {
    @media only screen and (max-width: 575px) {
      width: 80vw;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 70vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 87vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 78vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 60vw;
    }
    @media only screen and (min-width: 1600px) {
      width: 58vw;
    }
    @media only screen and (min-width: 2560px) {
      width: 49vw;
    }
  }

  .mantine-InputWrapper-root {
    @media only screen and (max-width: 575px) {
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin-right: 2vw;
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      margin-right: 2vw;
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 1600px) {
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 2560px) {
      display: flex;
      align-items: center;
    }

    .mantine-TextInput-label,
    .mantine-Select-label {
      margin-bottom: 0.8vh;
      @media only screen and (max-width: 575px) {
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 16vw;
        margin-right: 5vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 14vw;
        margin-right: 3vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 14vw;
        margin-right: 4vw;
      }
      @media only screen and (min-width: 1600px) {
        width: 10vw;
        margin-right: 2vw;
      }
      @media only screen and (min-width: 2560px) {
        width: 8vw;
        margin-right: 2vw;
      }
    }

    .mantine-TextInput-wrapper,
    .mantine-Select-wrapper {
      width: 12vw;
      @media only screen and (max-width: 575px) {
        width: 34vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 32vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: 1.5vw;
        width: 28vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 22vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        margin-left: 1vw;
        width: 30vw;
      }
      @media only screen and (min-width: 1600px) {
        width: 20vw;
      }
      @media only screen and (min-width: 2560px) {
        margin-left: 0.4vw;
        width: 26vw;
      }
    }
  }

  .wide-input {
    .mantine-TextInput-wrapper,
    .mantine-Select-wrapper {
      width: 35vw;
      @media only screen and (max-width: 575px) {
        width: 74vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 66vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: -4vw;
        width: 60vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-left: -3vw;
        width: 55vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        margin-left: -6vw;
        width: 43vw;
      }
      @media only screen and (min-width: 1600px) {
        margin-left: -2vw;
        width: 43vw;
      }
      @media only screen and (min-width: 2560px) {
        margin-left: -3vw;
        width: 37.5vw;
      }
    }
  }

  .accordion {
    position: relative;
    top: 2vh;
    margin-bottom: 4vh;
    @media only screen and (max-width: 575px) {
      left: -1vw;
      width: 84vw;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      left: -2vw;
      width: 74vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      left: -2vw;
      width: 86vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      left: -3vw;
      width: 78vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      left: -2vw;
      width: 61vw;
    }
    @media only screen and (min-width: 1600px) {
      left: -2vw;
      width: 58vw;
    }
    @media only screen and (min-width: 2560px) {
      left: -1.5vw;
      width: 48vw;
    }

    .mantine-Accordion-label {
      font-weight: 700;
    }

    .mantine-Accordion-item {
      padding: 0.5vw;
      /*border: #4b4b4b solid 2px;*/
    }

    .mantine-Accordion-panel {
      @media only screen and (max-width: 575px) {
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: 0.5vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-left: 1vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        margin-left: 0.5vw;
      }
      @media only screen and (min-width: 1600px) {
        margin-left: 1vw;
      }
      @media only screen and (min-width: 2560px) {
        margin-left: 0.5vw;
      }
    }
  }

  .submit-save-buttons {
    .mantine-Button-root {
      width: 6vw;
      @media only screen and (max-width: 575px) {
        width: 20vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 16vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 14vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 12vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 10vw;
      }
      @media only screen and (min-width: 1600px) {
      }
      @media only screen and (min-width: 2560px) {
      }
    }
  }
}

@media only screen and (max-width: 575px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1600px) {
}
@media only screen and (min-width: 2560px) {
}
