@import '../../../app.scss';

.profile {
  position: absolute;
  left: 3vw;
  top: 5vh;
  width: 88vw;
  height: 90%;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 575px) {
    left: 7vw;
    top: 12vh;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    top: 2vh;
    left: 14vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    top: 2vh;
    left: 12vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    left: 10vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 92vw;
    left: 7vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 94vw;
    left: 5vw;
    top: 2vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 94vw;
    left: 4vw;
    top: 2vh;
  }

  .mantine-Grid-root {
    width: 62vw;
    @media only screen and (max-width: 575px) {
      width: 86vw;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 80vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 84vw;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 84vw;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    }

    @media only screen and (min-width: 1600px) {
    }

    @media only screen and (min-width: 2560px) {
    }
    .mantine-Grid-inner {
      display: flex;
      justify-content: space-evenly;

      .mantine-Grid-col {
        padding: 0.5vw;

        @media only screen and (max-width: 575px) {
          padding: 0 3vw 2vh 0;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
          padding: 0 2vw 2vh 0;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          padding: 0 2vw 2vh 0;
        }

        .mantine-Title-root {
          margin: 0 0 2vh 0;
          color: var(--title-color-light);
          [data-theme='dark'] & {
            color: var(--title-color-dark);
          }
        }

        h4 {
          color: var(--title-color-light);
          [data-theme='dark'] & {
            color: var(--title-color-dark);
          }
          margin: 0;
        }

        .mantine-InputWrapper-root {
          .mantine-TextInput-label {
            margin-top: 2vh;
            font-weight: 500;
            color: var(--title-color-light);
            [data-theme='dark'] & {
              color: var(--title-color-dark);
            }
            @media only screen and (max-width: 575px) {
            }

            @media only screen and (min-width: 576px) and (max-width: 767px) {
            }

            @media only screen and (min-width: 768px) and (max-width: 991px) {
            }

            @media only screen and (min-width: 992px) and (max-width: 1199px) {
            }

            @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            }

            @media only screen and (min-width: 1600px) {
              margin-top: 1.2vh;
            }

            @media only screen and (min-width: 2560px) {
              margin-top: 1vh;
            }
          }

          .mantine-TextInput-wrapper {
            width: 16vw;
            margin-top: 0.5vh;
            padding: 0;
            @media only screen and (max-width: 575px) {
            }

            @media only screen and (min-width: 576px) and (max-width: 767px) {
            }

            @media only screen and (min-width: 768px) and (max-width: 991px) {
              width: 40vw;
            }

            @media only screen and (min-width: 992px) and (max-width: 1199px) {
              width: 20vw;
            }

            @media only screen and (min-width: 1200px) and (max-width: 1599px) {
              width: 14vw;
              margin-top: 0.5vh;
            }

            @media only screen and (min-width: 1600px) {
              width: 14vw;
              margin-top: 0.6vh;
            }

            @media only screen and (min-width: 2560px) {
              width: 14vw;
              margin-top: 0.5vh;
            }
          }
        }
      }
    }
  }

  .mantine-Select-root {
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-weight: 500;
    color: var(--title-color-light);
    [data-theme='dark'] & {
      color: var(--title-color-dark);
    }

    .mantine-Select-wrapper {
      @media only screen and (max-width: 575px) {
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 40vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 20vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 14vw;
        margin-top: 0.5vh;
      }

      @media only screen and (min-width: 1600px) {
        width: 14vw;
        margin-top: 0.6vh;
      }

      @media only screen and (min-width: 2560px) {
        width: 14vw;
        margin-top: 0.5vh;
      }
    }
  }

  .mantine-Checkbox-body {
    margin-top: 0.3vw;
  }

  .mantine-Button-root {
    width: 100%;
  }

  .mantine-Group-root {
    margin-top: 1vh;
    @media only screen and (max-width: 575px) {
      margin-top: 1.5vh;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      margin-top: 2vh;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin-top: 1vh;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    }

    @media only screen and (min-width: 1600px) {
      margin-top: 0.5vh;
    }

    @media only screen and (min-width: 2560px) {
      margin-top: 0vh;
    }
  }

  .UpdateConfirmation {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
  }

  .name {
    .mantine-TextInput-root {
      .mantine-TextInput-wrapper {
        width: 30vw;
        margin-top: 0.5vw;
        padding: 0;
        @media only screen and (max-width: 575px) {
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          margin-top: 0.5vh;
          width: 83vw !important;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 41.5vw !important;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 30vw !important;
          margin-top: 0.5vh;
        }

        @media only screen and (min-width: 1600px) {
          width: 29.6vw !important;
          margin-top: 0.6vh;
        }

        @media only screen and (min-width: 2560px) {
          width: 29.6vw !important;
          margin-top: 0.5vh;
        }
      }
    }
  }

  .buttons {
    margin-top: 2vh;
  }
}
