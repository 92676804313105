@import '../../../../app.scss';

.choose-entity {
  .mantine-InputWrapper-root,
  .mantine-Select-root {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .mantine-TextInput-label,
    .mantine-Select-label {
      width: 20vw;
      font-size: 1vw;
      color: var(--title-color-light);
      [data-theme='dark'] & {
        color: var(--title-color-dark);
      }
      @media only screen and (max-width: 575px) {
        width: 28vw;
        font-size: 2.8vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 24vw;
        font-size: 2vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 20vw;
        font-size: 1.6vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 20vw;
        font-size: 1.4vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 16vw;
        font-size: 1.2vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 8vw;
        font-size: 0.8vw;
      }

      @media only screen and (min-width: 2560px) {
        width: 6vw;
        font-size: 0.6vw;
      }
    }

    .mantine-TextInput-wrapper,
    .mantine-Select-wrapper {
      @media only screen and (max-width: 575px) {
        width: 42vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 36vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 30vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 24vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 20vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 14vw;
      }

      @media only screen and (min-width: 2560px) {
        width: 12vw;
      }
    }
  }
}
