@import '../../../app.scss';

.transactions-table{
  .mantine-Modal-root{
    background-color:  var( --general-background-ligh) !important;
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color:  var( --general-background-dark) !important;
      color: $zarph-white;
    }  
  }
  text-align: center;

  .mantine-Table-table {
    background-color: $zarph-white;
    text-align: center;
  }

  .mantine-Table-thead {
    background-color: $zarph-color;
    color: $zarph-white;
      font-weight: 500;
    border: 1px solid white;

    .mantine-Table-th {
      text-align: center;
      width: 5%;
      border: 1px solid white;
    }
  }
    .mantine-Table-td svg{
        color:  var(--title-color-light) !important;
        [data-theme='dark'] & {
          color:  var(--title-color-dark) !important;
           }    }
    svg{
      color:  var(--text-color-light) ;
  [data-theme='dark'] & {
    color:  var(--text-color-dark) ;
  }
    }
  }
