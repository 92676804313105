@import '../../../../app.scss';

.helpdesk-ticket-classification {
  padding: 0 !important;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2%;

  @media only screen and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
