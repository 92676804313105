@import '../../../app.scss';

.reasons-declarations {
  position: absolute;
  left: 3vw;
  top: 5vh;
  width: 88vw;
  height: 90%;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }
  @media only screen and (max-width: 575px) {
    left: 5vw;
    top: 12vh;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 92vw;
    left: 7vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 94vw;
    left: 5vw;
    top: 2vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 94vw;
    left: 4vw;
    top: 5vh;
  }

  .tables {
    display: flex;
    align-items: flex-start;
    .mantine-Group-root {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: 575px) {
        width: 50vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 40vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      }
      @media only screen and (min-width: 1600px) {
      }
      @media only screen and (min-width: 2560px) {
      }
    }

    .mantine-Table-table {
      width: 45vw;
      @media only screen and (max-width: 575px) {
        width: 82vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 80vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 84vw;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 44vw;
      }
      @media only screen and (min-width: 1600px) {
      }
      @media only screen and (min-width: 2560px) {
        width: 45vw;
      }
    }
  }
  .mantine-Table-table {
    .mantine-Table-thead {
      .mantine-Table-th {
        color: var(--title-color-light);
        [data-theme='dark'] & {
          color: var(--title-color-dark);
        }
      }
    }
    svg {
      color: var(--title-color-light) !important;
      [data-theme='dark'] & {
        color: var(--title-color-dark) !important;
      }
    }
  }
}
@import '../../../app.scss';

.add-reason-declaration {
  .mantine-Select-root {
    margin-right: 1.3vw;
  }
  .mantine-TextInput-label,
  .mantine-Select-label {
    margin-bottom: 0.5vh;
  }
  .mantine-TextInput-wrapper,
  .mantine-Select-wrapper {
    width: 10vw;
    @media only screen and (max-width: 575px) {
      width: 28vw;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 22vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 14vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 11vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    }
    @media only screen and (min-width: 1600px) {
      width: 9vw;
    }
    @media only screen and (min-width: 2560px) {
      width: 9vw;
    }
  }

  .add-reason-declaration-description {
    margin-top: 1vh;
    .mantine-TextInput-root {
      .mantine-TextInput-wrapper {
        width: 22vw;
        margin-bottom: 2vh;
        @media only screen and (max-width: 575px) {
          width: 60vw;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 48vw;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 31vw;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 25vw;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 22.5vw;
        }
        @media only screen and (min-width: 1600px) {
          width: 20vw;
        }
        @media only screen and (min-width: 2560px) {
          width: 20vw;
        }
      }
    }
  }
}
