@import '../../../app.scss';
.mantine-Modal-root {
  display: flex;

  .mantine-Modal-header {
    background-color: var(--background-color-light) !important;
    color: $zarph-white;
    padding: 5px 20px 5px 40px !important;

    [data-theme='dark'] & {
      background-color: var(--background-color-dark) !important;
      color: $zarph-white;
    }
  }

  .mantine-Modal-title {
    font-weight: 500 !important;
    font-size: medium;

    @media only screen and (max-width: 575px) {
      font-size: larger;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      font-size: large;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      font-size: medium;
    }

    @media only screen and (min-width: 1600px) {
      font-size: x-large !important;
    }

    @media only screen and (min-width: 2560px) {
    }
  }

  .mantine-Modal-close {
    color: #f8f9fa !important;
  }

  .mantine-Modal-close :hover {
    color: #1d5580;
  }
  .mantine-Modal-content {
    flex-direction: column !important;
    justify-content: center !important;
    .mantine-Button-root {
      background-color: var(--background-color-light);
      color: $zarph-white;
      [data-theme='dark'] & {
        background-color: var(--background-color-dark);
        color: $zarph-white;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

@media only screen and (min-width: 2560px) {
}
