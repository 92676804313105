@import '../../../app.scss';

.transaction-details {
  margin-top: 20px;
  background-color: var(--general-background-light) !important;

  [data-theme='dark'] & {
    background-color: var(--general-background-dark) !important;
    color: $zarph-white;
  }

  .mantine-Group-root {
    display: block;
  }
  .mantine-Grid-root {
    margin-bottom: 25px;
    .mantine-Grid-col {
      padding: 10px 10px 10px 0;
      color: var(--text-color-light);
      [data-theme='dark'] & {
        color: var(--text-color-dark);
      }
    }
  }

  .mantine-Title-root {
    color: var(--title-color-light);
    [data-theme='dark'] & {
      color: var(--title-color-dark);
    }
  }

  .mantine-Button-root {
    margin-top: 15px;
    background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    }
    svg {
      color: $zarph-white;
      margin-right: 0.5vw;
    }
  }
}

@media only screen and (max-width: 575px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1600px) {
}
@media only screen and (min-width: 2560px) {
}
