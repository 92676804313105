@import "../../../app.scss";

.comissions-table{
    text-align: center;

    .mantine-Table-table {
      background-color: $zarph-white;
      text-align: center;
    }
  
    .mantine-Table-thead {
      background-color: $zarph-color;
      color: $zarph-white;
      font-weight: 500;
      border: 1px solid white;
  
      .mantine-Table-th {
        text-align: center;
        width: 5%;
        border: 1px solid white;
      }
    }
    }
    
