@import '../../../app.scss';

.mantine-Notification-description{
  color:#fcfcfc !important;
}

.mantine-Notification-title {
  color:#fcfcfc !important;
}

.mantine-Notifications-notification {
  background-Color: rgb(136, 0, 0) !important;
}