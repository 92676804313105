@import '../../../app.scss';

.transactions {
  position: absolute;
  left: 3vw;
  top: 5vh;
  width: 88vw;
  height: 90%;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }
  @media only screen and (max-width: 575px) {
    left: 5vw;
    top: 12vh;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    left: 7vw;
    top: 6vh;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 92vw;
    left: 7vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 94vw;
    left: 5vw;
    top: 2vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 94vw;
    left: 4vw;
    top: 2vh;
  }
  .mantine-Table-table {
    .mantine-Table-thead {
      .mantine-Table-th {
        color: var(--title-color-light);
        [data-theme='dark'] & {
          color: var(--title-color-dark);
        }
      }
    }
  }
}

.transactionDetails {
  height: auto;
  .mantine-Grid-col {
    padding: 0.5vh 0.5vw 0.5vh 0;
  }

  .mantine-Table-table {
    .mantine-Table-thead {
      .mantine-Table-th {
        color: white;
      }
    }
    svg {
      color: var(--text-color-light) !important;
      [data-theme='dark'] & {
        color: var(--text-color-dark) !important;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 575px) {
  .transactionDetails {
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1vw;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .transactionDetails {
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1vw;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .transactionDetails {
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1vw;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

@media only screen and (min-width: 2560px) {
}
