@import '../../../app.scss';

.dashboard {
  position: absolute;
  left: 3vw;
  top: 5vh;
  width: 88vw;
  height: 90%;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }
  @media only screen and (max-width: 575px) {
    left: 5vw;
    top: 12vh;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 92vw;
    left: 7vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 94vw;
    left: 5vw;
    top: 2vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 94vw;
    left: 4vw;
    top: 2vh;
  }

  .mantine-Table-table {
    background-color: var(--general-background-light);
    [data-theme='dark'] & {
      background-color: var(--general-background-dark);
    }
    text-align: center;
  }

  .mantine-Table-thead {
    background-color: var(--background-color-light);
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
    }
    color: white;
    border: 1px solid white;

    .mantine-Table-th {
      text-align: center;
      width: 5%;
      border: 1px solid white;
    }

    .values {
      width: 2% !important;
    }
  }

  .mantine-Table-tbody {
    .cellWithValue {
      background-color: var(--background-color-light);
      [data-theme='dark'] & {
        background-color: var(--background-color-dark);
      }
      color: $zarph-white;
    }
  }

  .mantine-Table-table {
    .mantine-Table-thead {
      .mantine-Table-th {
        color: white;
      }
    }
  }

  .mantine-Table-td {
    .check {
      width: 0.8vw;
      fill: #538539;
    }
    .cross {
      width: 0.8vw;
      fill: red;
    }
  }

  .iconography {
    display: flex;
    flex-wrap: wrap;
    width: 94vw;
    font-size: 1vw;
    margin-top: 0.8vw;
    width: 15vw;
    background-color: var(--general-background-light);
    [data-theme='dark'] & {
      background-color: var(--general-background-dark);
    }

    .mantine-Grid-inner {
      @media only screen and (max-width: 575px) {
        flex-direction: column;
        font-size: 2.5vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        flex-direction: column;
        font-size: 1.5vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1vw;
        font-size: 1.5vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 86vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1vw;
        font-size: 0.8vw;
      }

      @media only screen and (min-width: 1600px) {
        font-size: 0.6vw;
      }

      @media only screen and (min-width: 2560px) {
        font-size: 0.4vw;
      }

      .mantine-Grid-col {
        flex: 0.5 0.5 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          color: var(--text-color-light);
          [data-theme='dark'] & {
            color: var(--text-color-dark);
          }
          margin-left: 1vw;
        }
        .CheckIcon {
          width: 0.8vw;
          fill: #538539;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .XIcon {
          width: 0.8vw;
          fill: red;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .transportIcon {
          width: 0.8vw;
          fill: orange;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .plusIcon {
          width: 0.8vw;
          fill: $zarph-light-color;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .errorIcon {
          width: 0.8vw;
          fill: rgb(192, 79, 13);
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .squareMax {
          width: 0.8vw;
          fill: red;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .squareMed {
          width: 0.8vw;
          fill: $zarph-secondary;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .squareMin {
          width: 0.8vw;
          fill: $zarph-light-color;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .dashEmptyCell {
          background-color: $zarph-light-color;
          color: $zarph-white;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }

        .dashWithValue {
          background-color: $zarph-color;
          color: $zarph-white;
          @media only screen and (max-width: 575px) {
            width: 2vw;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 2vw;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 2vw;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 2vw;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            width: 1vw;
          }

          @media only screen and (min-width: 1600px) {
            width: 1vw;
          }

          @media only screen and (min-width: 2560px) {
            width: 0.8vw;
          }
        }
      }
    }
  }

  h2 {
    color: $zarph-dark-color;
  }
}
