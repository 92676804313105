@import '../../../../../app.scss';

.register-equip-helpdesk {
  margin-top: 8vh;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }

  .mantine-Group-root {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 575px) {
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    }

    @media only screen and (min-width: 1600px) {
    }

    @media only screen and (min-width: 2560px) {
    }
  }

  @media only screen and (max-width: 575px) {
    margin-top: 12vh;

    .mantine-TableScrollContainer-scrollContainer {
      .mantine-ScrollArea-viewport {
        width: 92vw;
      }
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .mantine-TableScrollContainer-scrollContainer {
      .mantine-ScrollArea-viewport {
        width: 92vw;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mantine-TableScrollContainer-scrollContainer {
      .mantine-ScrollArea-viewport {
        width: 92vw;
      }
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .mantine-TableScrollContainer-scrollContainer {
      .mantine-ScrollArea-viewport {
        width: 92vw;
      }
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .mantine-TableScrollContainer-scrollContainer {
      .mantine-ScrollArea-viewport {
        width: 92vw;
      }
    }
  }
  @media only screen and (min-width: 1600px) {
    .mantine-TableScrollContainer-scrollContainer {
      .mantine-ScrollArea-viewport {
        width: 92vw;
      }
    }
  }
  @media only screen and (min-width: 2560px) {
    .mantine-TableScrollContainer-scrollContainer {
      .mantine-ScrollArea-viewport {
        width: 92vw;
      }
    }
  }
  .mantine-Group-root {
    svg {
      color: var(--title-color-light);
      [data-theme='dark'] & {
        color: var(--title-color-dark);
      }
      margin-left: 1vw;
    }
  }

  .entities {
    margin-top: 4vh;
    .mantine-TextInput-root {
      width: 50vw;
      display: flex;
      align-items: center;
      margin-top: 0.5vh;

      .mantine-TextInput-label {
        margin-right: 0.5vw;
        color: var(--title-color-light);
        [data-theme='dark'] & {
          color: var(--title-color-dark);
        }
        width: 8vw;
        @media only screen and (max-width: 575px) {
          width: 20vw;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 17vw;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 18vw;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 16vw;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 14vw;
        }

        @media only screen and (min-width: 1600px) {
          width: 6vw;
        }

        @media only screen and (min-width: 2560px) {
          width: 6vw;
        }
      }
      .mantine-TextInput-input {
        width: 30vw;
        @media only screen and (max-width: 575px) {
          width: 22vw;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 30vw;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 30vw;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 34vw;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 37vw;
        }

        @media only screen and (min-width: 1600px) {
          width: 42vw;
        }

        @media only screen and (min-width: 2560px) {
          width: 42vw;
        }
      }
    }
  }

  .shop {
    margin-top: 5vh;
    .mantine-TextInput-root {
      display: flex;
      align-items: center;
      .mantine-TextInput-label {
        color: var(--title-color-light);
        [data-theme='dark'] & {
          color: var(--title-color-dark);
        }
        @media only screen and (max-width: 575px) {
          width: 10vw;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 16vw;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 10vw;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 8vw;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 6vw;
        }

        @media only screen and (min-width: 1600px) {
          width: 4vw;
        }

        @media only screen and (min-width: 2560px) {
        }
      }

      .mantine-TextInput-input {
        width: 30vw;
        @media only screen and (max-width: 575px) {
          width: 22vw;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 16vw;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 20vw;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 21vw;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 23vw;
        }

        @media only screen and (min-width: 1600px) {
          width: 23vw;
        }

        @media only screen and (min-width: 2560px) {
        }
      }
    }
  }

  .licence,
  .posid {
    margin-top: 5vh;
    .mantine-TextInput-root,
    .mantine-DateTimePicker-root {
      display: flex;
      align-items: center;

      .mantine-TextInput-label,
      .mantine-DateTimePicker-label {
        color: var(--title-color-light);
        [data-theme='dark'] & {
          color: var(--title-color-dark);
        }
        width: 8vw;
        @media only screen and (max-width: 575px) {
          width: 20vw;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 16vw;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 10vw;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 8vw;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 6vw;
        }

        @media only screen and (min-width: 1600px) {
          width: 4vw;
        }

        @media only screen and (min-width: 2560px) {
        }
      }
      .mantine-TextInput-input,
      .mantine-DateTimePicker-input {
        width: 30vw;
        @media only screen and (max-width: 575px) {
          width: 47vw;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
          width: 50vw;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          width: 52vw;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 52vw;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          width: 54vw;
        }

        @media only screen and (min-width: 1600px) {
          width: 51vw;
        }

        @media only screen and (min-width: 2560px) {
        }
      }
      h3 {
        color: var(--title-color-light);
        [data-theme='dark'] & {
          color: var(--title-color-dark);
        }
        margin: 2vh;
      }
    }

    .entity-table {
      margin-top: 2vh;
      .mantine-Table-table {
        background-color: $zarph-white;
        text-align: center;
      }
      .mantine-Table-thead {
        background-color: $zarph-color;
        color: white;
        border: 1px solid white;

        .mantine-Table-th {
          text-align: center;
          width: 5%;
          border: 1px solid white;
          color: white !important;
          font-weight: 400;
        }
      }
      @media only screen and (max-width: 575px) {
        width: 78vw !important;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 70vw !important;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 56vw !important;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 52vw !important;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 36vw !important;
      }

      @media only screen and (min-width: 1600px) {
        width: 32vw !important;
      }

      @media only screen and (min-width: 2560px) {
        width: 16vw !important;
      }
    }
  }

  .mantine-Button-root {
    margin-top: 3vh;
    /*     background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    } */
  }
}
